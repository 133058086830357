import { getPointFromEvent } from '../../utils/utils';
import { Shape, Point, Slide } from '../../models';

export class Image extends Shape {
  static model = 'Image' as const;
  static base = Shape;

  declare type: 'Image';

  src!: string;

  static fields() {
    return {
      ...Shape.fields(),
      src: this.string(null),
      type: this.string('Image'),
    };
  }

  static createWithOrWithoutPosition(
    src: string,
    slide: Slide,
    p: Point = new Point({ x: window.innerWidth / 2, y: window.innerHeight / 2 }),
  ) {
    return new Promise<Image>((resolve, reject) => {
      const img = new window.Image();
      img.onload = (_ev: Event) => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        const ratio = width / height;

        const newHeight = 400 / ratio;

        const maxWidth = 1000;
        const maxHeight = 735;

        if (width > maxWidth || height > maxHeight) {
          let newWidth = maxWidth;
          let newHeight = maxHeight;

          if (ratio > 1) {
            newHeight = maxWidth / ratio;
          } else {
            newWidth = maxHeight * ratio;
          }

          const canvas = document.createElement('canvas');
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0, width, height, 0, 0, newWidth, newHeight);
          src = canvas.toDataURL('image/webp');
        } else {
          // We redraw the picture onto a canvas to get around svg2pdf.js bug that cant handle pictures with exif orientation
          // https://github.com/yWorks/svg2pdf.js/issues/315
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0, width, height, 0, 0, width, height);
          src = canvas.toDataURL('image/webp');
        }

        const p1 = { x: p.x - 200, y: p.y - newHeight / 2 };
        const p2 = { x: p1.x + 400, y: p1.y + newHeight };

        const image = this.create({
          parentId: slide._id,
          src,
          transform: {},
          points: [p1, p2],
          boardId: slide.board._id,
        });

        // image.data = canvas

        resolve(image);
      };

      img.onerror = (ev: string | Event) => {
        reject(ev);
      };

      img.src = src;
    });
  }

  static loadImage(src: string) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new window.Image();
      img.onload = (_ev: Event) => {
        resolve(img);
      };
      img.onerror = (ev: string | Event) => {
        reject(ev);
      };
      img.src = src;
    });
  }

  static async scaleImage(src: string) {
    const img = await this.loadImage(src);

    const width = img.naturalWidth;
    const height = img.naturalHeight;

    const ratio = width / height;

    const maxWidth = 1000;
    const maxHeight = 735;

    const needsScale = width > maxWidth || height > maxHeight;

    if (!needsScale && src.startsWith('data:image/webp;')) {
      return src;
    }

    let newWidth = maxWidth;
    let newHeight = maxHeight;

    if (ratio > 1) {
      newHeight = maxWidth / ratio;
    } else {
      newWidth = maxHeight * ratio;
    }

    const canvas = document.createElement('canvas');
    canvas.width = needsScale ? newWidth : width;
    canvas.height = needsScale ? newHeight : height;

    const ctx = canvas.getContext('2d');
    ctx?.drawImage(img, 0, 0, width, height, 0, 0, needsScale ? newWidth : width, needsScale ? newHeight : height);

    // const imgType = img.outerHTML.split(';')[0].slice(21)
    return canvas.toDataURL('image/webp');
  }

  onMouseUp(ev: MouseEvent) {
    const p = getPointFromEvent(ev, this.matrix?.value, true);
    const last = this.getLastPoint();

    if (!this.hasMoved) {
      return this.cancelDrawing();
    }

    if (last.fixed) {
      this.addPoint(p, true);
    } else {
      Object.assign(last, { x: p.x, y: p.y, fixed: true });
    }

    this.finishShape();
  }
}

Image.boot();
